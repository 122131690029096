import React from "react"
import { graphql, Link  } from "gatsby"
import styled, { ThemeProvider } from "styled-components"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Img from "../components/Elements/Img"
import SectionContainer from "../components/SectionContainer"
//import VideoPlayer from "../components/VideoPlayer/index"
import { Container, Row, Col } from "../components/Grid"
import {
  SectionBody,
  AccentHeader,
  PageTitle,
} from "../components/Elements/index"

const WorkLink = styled(props => <Link {...props} />)`
  color: #414141;
  &:active {
    color: #414141;
  }

  &:visited {
    color: #414141;
  }
`


const Footer = styled.footer`
  background: #467DB8;
  display: flex;
  height: 120px;
  align-items: center;
`

const RevomeMarginWrapper = styled.div` 
  .gatsby-image-wrapper {
    margin: 0;
  }
`
const ColorToAccentHeader = styled.div` 
  h3{
    color: #467DB8;
  }
  
`

const labHero = ({ data }) => (
  <Layout>
    <ThemeProvider
      theme={{
        backgroundColor: "#FFFFFF",
        primaryColor: "#3F3F3F",
        dividerColor: "#467DB8;",
        invert: {
          primaryColor: "#FFFFFF",
          dividerColor: "#FFFFFF;",
          backgroundColor: "#467DB8",
        },
      }}
    >
      <>
        <Seo title="Lab Hero" />
        <SectionContainer>
          <Container>
            <ColorToAccentHeader>
            <AccentHeader size="large">
              <WorkLink to="/">
                  The Work
                </WorkLink>
            </AccentHeader>
            </ColorToAccentHeader>
            <PageTitle>
              <div style={{ color: "#1B1464" }}>
                Always A Hero
              </div>
            </PageTitle>
            <Row>
              <Col md={4}>
              <ColorToAccentHeader>
                <AccentHeader>The Problem</AccentHeader>
                </ColorToAccentHeader>
                <SectionBody size="regular">
                In honor of Medical Laboratory Professionals Week Roche wanted to recognize the men and women whose tireless work has a significant impact on healthcare. Our challenge was to create a campaign that celebrated and honored laboratory professionals in a way that was authentic and credible during Lab Week when there would be a significant amount of chatter by competitor brands around the globe doing the same.
                </SectionBody>
              </Col>
              <Col md={4}>
                <AccentHeader></AccentHeader>
                <SectionBody size="extraLarge" weight="bold">
                  <div style={{ color: "#1B1464" }}>
                    Lab workers often feel like they reside in the shadows of healthcare. And now, with the pandemic, that forgotten feeling may be more acute than ever.
                  </div>
                </SectionBody>
              </Col>
            </Row>
          </Container>
        </SectionContainer>

        <SectionContainer noTopMargin noBottomMargin>
          <RevomeMarginWrapper>
            <Img fluid={data.image1.childImageSharp.fluid} />
          </RevomeMarginWrapper>
        </SectionContainer>

        <SectionContainer invert noTopMargin noBottomMargin>
          <Container style={{ paddingTop: "60px", paddingBottom: "60px" }}>
            <Row>
              <Col md={4}>
                <AccentHeader>The Idea</AccentHeader>
                <SectionBody size="regular">
                Despite being understaffed, laboratorians continue to perform an unprecedented number of diagnostic tests as a result of COVID-19 in addition to facilitating the routine testing needs of patients with many other medical conditions. They are essential to healthcare. They are warriors. And the long hours they’ve worked, their resilience, and their fierce determination to find answers for patients must be recognized via an omnichannel digital experience.
                </SectionBody>
              </Col>
              <Col md={4}>
                <AccentHeader></AccentHeader>
                <SectionBody size="extraLarge" weight="bold">
                  Lab workers deserve an authentic acknowledgement for the work they do—we need to shine a light on these often forgotten but always important professionals.
                </SectionBody>
              </Col>
            </Row>
          </Container>
        </SectionContainer>

        <SectionContainer noTopMargin noBottomMargin>
          <Container style={{ paddingTop: "30px", paddingBottom: "30px" }}>
            <Row>
              <Col md={4}>
                <Img fluid={data.image2.childImageSharp.fluid} />
              </Col>
              <Col md={4}>
                <Img fluid={data.image3.childImageSharp.fluid} />
              </Col>
            </Row>          
          </Container>
        </SectionContainer>

        <RevomeMarginWrapper>
        <SectionContainer noTopMargin>
          <RevomeMarginWrapper>
            <Img fluid={data.image4.childImageSharp.fluid} />
          </RevomeMarginWrapper>
        </SectionContainer>
        </RevomeMarginWrapper>

        <Container>
        <Row>
              <Col md={8}>
              <ColorToAccentHeader>
                <AccentHeader>The Execution</AccentHeader>
              </ColorToAccentHeader>
                <SectionBody size="regular">
                We began by creating a shield that could act as a badge of honor, recognizing the life changing work these professionals do everyday. We designed a landing page experience where medical professionals could upload a picture, create a customized shield and share on their social pages to recognize a lab professional they admired. They could also discover a library of materials and courses in a wide range of media, designed exclusively for lab professionals. In addition to the landing page, this campaign leveraged paid and organic social media, digital ads and paid search to drive awareness.
                </SectionBody>
              </Col>
            </Row>
          </Container>

          <RevomeMarginWrapper>
            <SectionContainer>
              <RevomeMarginWrapper>
                <Img fluid={data.image5.childImageSharp.fluid} />
              </RevomeMarginWrapper>
            </SectionContainer>
          </RevomeMarginWrapper>

          <RevomeMarginWrapper>
            <SectionContainer noBottomMargin>
              <RevomeMarginWrapper>
                <Img fluid={data.image6.childImageSharp.fluid} />
              </RevomeMarginWrapper>
            </SectionContainer>
          </RevomeMarginWrapper>

        <Footer></Footer>
      </>
    </ThemeProvider>
  </Layout>
)

export default labHero

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "2023/lab-hero/image1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2048) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    image2: file(relativePath: { eq: "2023/lab-hero/image2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2048) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    image3: file(relativePath: { eq: "2023/lab-hero/image3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2048) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    image4: file(relativePath: { eq: "2023/lab-hero/image4.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2048) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    image5: file(relativePath: { eq: "2023/lab-hero/image5.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2048) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    image6: file(relativePath: { eq: "2023/lab-hero/image6.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2048) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
  }
`
